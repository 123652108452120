<template>
    <Hero />

    <div class="aa-home-content">
        <div class="banners-home">
            <div class="principal-grid">
                <Suspense>
                    <Info />
                </Suspense>
            </div>
            <div class="social">
                <div class="aa-noticias-home-titulo">
                    <span class="aa-text-h1 text-start d-block text-uppercase p-1">
                        Síguenos
                    </span>
                    <div class="social">
                        <div class="buttons">
                            <a href="https://www.facebook.com/iesfraybartolome" target="_blank" class="fb" title="Síguenos en Facebook"><img src="../../../assets/UI/fb.svg" /></a>
                            <a href="https://www.instagram.com/ies_fray_bartolome/" target="_blank" class="ig" title="Síguenos en Instagram"><img src="../../../assets/UI/instagram.svg" /></a>
                        </div>
                    </div>
                    
                </div>

                <iframe src="//lightwidget.com/widgets/ba50daff24f851b8a9fea4960828793d.html" scrolling="no" allowtransparency="true" class="lightwidget-widget" style="width:100%;border:0;overflow:hidden;"></iframe>

                
            </div>
        </div>
        <div class="tablon mt-1">
            <Anuncios />
        </div>
        <div class="noticias mt-1">
            <Noticias />
        </div>
    </div>
        <div class="banners">
            <Banners />
        </div>
</template>

<script>
import { defineAsyncComponent, onMounted } from 'vue'

import useNoticias from '../composables/useNoticias'
import useOfertas from '../composables/useOfertas'
import useAnuncios from '../composables/useAnuncios'

export default {
    components: { 
        Hero: defineAsyncComponent( () => import('../components/Home/Hero.vue')),
        Info: defineAsyncComponent( () => import ('../components/Home/BannersHome.vue')),
        // FormandoBox: defineAsyncComponent( () => import('../components/Home/FormandoBox.vue')),
        Anuncios: defineAsyncComponent( () => import('../components/Home/AnunciosHome.vue')),
        Noticias: defineAsyncComponent( () => import('../components/Home/NoticiasHome.vue')),
        // OfertaEducativa: defineAsyncComponent( () => import('../components/Home/OfertaEducativa.vue')),
        Banners: defineAsyncComponent( () => import('../components/Home/Banners.vue'))
        },

    setup(){
        const { noticiasHome } = useNoticias()
        const { ofertaHome } = useOfertas()
        const { anunciosHome } = useAnuncios()

        onMounted(()=>{
            noticiasHome()
            ofertaHome()
            anunciosHome()
        })
    }
}
</script>

<style lang="sass" scoped>
.aa-noticias-home-titulo
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
.aa-home-content
    display: flex
    flex-direction: column
    flex-wrap: wrap
    justify-content: flex-start
    

    .banners-home
        max-width: 100%
        display: flex
        flex-wrap: wrap
        flex-direction: column-reverse
        justify-content: space-between
        .principal-grid
            width: 100%
            @include breakpoint(md)
                width: 70%
                display: flex
                flex-wrap: wrap
        .social
            width: 100%
            @include breakpoint(md)
                width: 30%
            
        @include breakpoint(md)
        flex-direction: row
    .tablon 
        border-bottom: 2px solid $base-color
        order: 2
        max-width: 100%

    .noticias 
        order: 3
        max-width: 100%
        border-bottom: 2px solid $base-color

    .banners
        order: 4
        max-width: 100%
    
.aa-noticias-home
    $root:&

    &-titulo
        position: relative
        @include breakpoint(md)
    &-line
        background-color: #86e4b9
        display: inline-block
        height: 20px
        left: 10px
        position: absolute
        top: 30px
        width: 120px
        z-index: -1

.buttons
    display: flex
    a
        display: flex
        align-items: center
        justify-content: center
        padding: 5px
        width: 30px
        height: 30px
        border-radius: 50%
        margin: 0 5px
        transition: transform 0.3s linear
        &:hover
            transform: translateY(-10px)
    .fb
        background-color: #2977f2
        img
            width: 40%
    .ig
        background-color: #bc2a8d
        img
            width: 60%

</style>